import React from 'react';
import { Col } from 'react-bootstrap';
import logo from '../assets/images/logo.png';
import './scss/SiteHeading.scss'

export default (props) => {
  return (
    <div className="SiteHeading">
      <Col className="heading-container">
        <div className="rectangle-copy helvar-merca-profi">
          <img src={logo} alt="Tenetor logo"/>
        </div>
      </Col>
    </div>
  )
}
